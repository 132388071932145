@use '@angular/material' as mat;
@import 'variables';


// include the common styles for angular material. we include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
// @include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$li-x-frontend-primary: mat.define-palette($lix-blue);
$li-x-frontend-accent: mat.define-palette($lix-green);

// The warn palette is optional (defaults to red).
$li-x-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$li-x-frontend-theme: mat.define-light-theme(
  $li-x-frontend-primary,
  $li-x-frontend-accent,
  $li-x-frontend-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($li-x-frontend-theme);

/* You can add global styles to this file, and also import other style files */

:root {
  --mat-form-field-container-height: 50px;
}

html,
body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: basier_squareregular, 'Helvetica Neue', sans-serif;
}
* {
  font-family: basier_squareregular;
}

p {
  margin-block-start: 0;
}

/*
-------------------------------------
 */

h1 {
  color: $primary;
  font-size: 70px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased
}

h2 {
  -webkit-font-smoothing: antialiased
}

h3 {
  -webkit-font-smoothing: antialiased
}

h4 {
  -webkit-font-smoothing: antialiased
}

.padder {
  padding-top: 10px;
}

// Pagination Styling
.ngx-pagination .current {
  background: $primary !important;
  border-radius: 24px !important;
}
.ngx-pagination a {
  border-radius: 24px !important;
  text-decoration: none;
}
.ngx-pagination {
  padding-top: 45px;
  padding-bottom: 45px;
}

// Material Custom Styling

// button
.mat-main-button {
  font-size: 20px !important;
  min-width: 160px !important;
  min-height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
button:focus {
  outline: none !important;
}

// form inputs
.mat-mdc-button {
  box-shadow: none;
  letter-spacing: normal !important;
}

.mat-mdc-form-field-infix {
  min-height: 50px !important;
  max-height: 50px !important;
}

.mat-mdc-raised-button, .lix-raised-button {
  box-shadow: none !important;
  letter-spacing: normal !important;
  &.large {
    height: 50px!important;
  }
  &.large-font {
    font-size: 20px !important;
  }
  &.medium-font {
    font-size: 18px !important;
  }
  &.outlined {
    border: 1px solid $primary !important;
    color: $primary !important;
  }
  &.solid {
    border: 1px solid $primary !important;
    color: $white !important;
  }
  &.mat-warn {
    border: 1px solid $warn !important;
  }
  &:disabled {
    border: none !important;
    color: rgba(0, 0, 0, .38) !important;
    background-color: #0000001f !important;
  }
}

// checkbox
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout {
  white-space: normal !important;
}
// rather than center the checkbox, put the checkbox in the first line
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
::ng-deep .mat-checkbox-inner-container {
  margin-top: 3px !important;
}

//links
a {
  text-decoration: none;
}
a:hover,
a:active {
  color: lightgray;
}

.mat-sidenav-container {
  min-height: 93vh !important;
  overflow: visible !important;
}

.mat-sidenav-content {
  overflow: visible !important;
}

// text
.title_large {
  font-size: 32px;
}
.title_small {
  font-size: 16px;
  color: $text-gray;
}
.mat-mdc-form-field {
  font-size: 17px;
}

// card
.mat-mdc-card {
  box-shadow: none !important;
  margin-top: 20px !important;
}

.mat-mdc-card-actions {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 16px !important;
}

// mat-tab-group styles
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  overflow: visible !important;
}

// slider
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-content {
  overflow: visible !important;
  white-space: normal !important;
}

// router-outlet
.content {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  background: $white;
}

//
// regular dialog
//
.mat-mdc-dialog-container {
  max-width: 642px !important;
}
.update-billing-dialog-container {
  .mat-mdc-dialog-container {
    width: 900px !important;
    max-width: 900px !important;
  }
}

//
// search dialog
//
.search-dialog-container .mat-mdc-dialog-container {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  background-color: transparent;

  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;

  .mat-mdc-dialog-surface {
    box-shadow: unset !important;
    background-color: unset !important;
  }

  // mobile dialog
  @media only screen and (max-width: 687px) {
    max-width: 100% !important;
    width: 100%;
  }
  // desktop dialog
  @media only screen and (min-width: 687px) {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 10%;
    left: 0;
    right: 0;
  }
}

// mobile search bar full width
//
// search dialog & add-cart dialog
//
@media only screen and (max-width: 687px) {
  .search-dialog-container {
    width: 100% !important;
    max-width: none !important;
    .mat-form-field-appearance-outline {
      width: 100%;
    }
  }
  // add global
  .mobile-full-width {
    width: 100% !important;
    max-width: none !important;
  }
}
// dialog box white overlay - search style depends upon
.cdk-overlay-dark-backdrop {
  background: rgba(255, 255, 255, 0.82) !important;
  filter: blur(10px) !important;
  -webkit-filter: blur(10px) !important;
  -moz-filter: blur(10px) !important;
  -o-filter: blur(10px) !important;
  -ms-filter: blur(10px) !important;
}

// custom Snackbar
.success-snack {
  background-color: white;
  border: 1px solid $border-blue;
  box-shadow: 0 0 3px $border-blue;
  color: $black;
}
.error-snack {
  background-color: white;
  border: 1px solid $warn;
  box-shadow: 0 0 3px $warn;
  color: $black;
  .mat-mdc-button {
    color: $warn;
  }
}
//this styling is for snackbars to stack over the cookie conset
.cdk-overlay-container {
  z-index: 10000;
}

h5 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
  color: $text-gray;
  -webkit-font-smoothing: antialiased
}

// Icon sizes
.icon-1_5x {
  transform: scale(1.5);
}

.page-end-padding {
  /* the .padder css has a top padding of 38px,
  * so it made sense to have similar padding in the bottom too
  */
  padding-bottom: 38px;
}

.breadcrumbs {
  p {
    font-size: 11px;
    padding-top: 17px;
    cursor: pointer;
    color: $link-text-gray;
  }
  span {
    font-size: 11px;
    padding-top: 17px;
    color: $link-text-gray;
    cursor: context-menu;
  }
  p:hover {
    color: $black;
  }
}

.global-btn-blue {
  height: 50px !important;
  background-color: $primary !important;
  color: $white !important;
  &.large-font {
    font-size: 20px !important;
  }
  &.medium-font {
    font-size: 18px !important;
  }
  .mat-icon {
    font-size: 18px;
  }
}

.global-btn-you-save {
  height: 50px !important;
  background-color: $primary !important;
  color: $white !important;
  font-size: 20px !important;
}

.global-btn-deactivate-user {
  color: $primary;
  min-width: 159px;
  font-size: 20px;
}

.global-btn-transparent {
  color: $primary !important;
  height: 50px !important;
  font-size: 14px !important;
  .mat-icon {
    font-size: 22px;
  }
  img {
    padding-right: 5px;
    width: 21px;
    height:21px
  }
}

.global-btn-delete {
  color: $text-light-gray !important;
  font-size: 16px !important;
  background-color: $white !important;
  .mat-icon {
    font-size: 20px !important;
  }
}

.global-btn-blinking {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.mdc-button>.mat-icon {
  -webkit-user-select: none !important;
  display: inline-block !important;
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
}

/*
  Global styles added after migrating to Material 17
 */

.dialog-close-btn {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 5px;
  opacity: .4;
  background-color: transparent;
  border: 0;
}

.mat-mdc-button .mat-mdc-button-persistent-ripple::before {
  background-color: transparent !important;
}

.mdc-button {
  padding: 0 16px !important;
}

.button-inline {
  height: 50px !important;
  margin-top: 0px !important;
}

.mat-mdc-tab-labels {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0,0,0,.12);
}

.title-text {
  h1 {
    font-size: 32px;
  }
}

.mat-mdc-card-subtitle, .mat-mdc-card-content {
  font-size: 14px;
}

.mat-mdc-form-field-error {
  font-size: 10px !important;
}




/**
* LIX General margin/padding/border classes
*/

.no-margin {
  margin: 0 !important;
}
.mt-0x {
  margin-top: 0;
}
.mt-1x {
  margin-top: calc(#{$margin-1x} * 1) !important;
}
.mt-2x {
  margin-top: calc(#{$margin-1x} * 2) !important;
}
.mt-3x {
  margin-top: calc(#{$margin-1x} * 3) !important;
}
.mt-5x {
  margin-top: calc(#{$margin-1x} * 5) !important;
}
.mt-10x {
  margin-top: calc(#{$margin-1x} * 10) !important;
}
.mt-15x {
  margin-top: calc(#{$margin-1x} * 15) !important;
}
.mt-20x {
  margin-top: calc(#{$margin-1x} * 20) !important;
}
.mt-30x {
  margin-top: calc(#{$margin-1x} * 30) !important;
}
.mt-35x {
  margin-top: calc(#{$margin-1x} * 35) !important;
}
.mb-2x {
  margin-bottom: calc(#{$margin-1x} *2) !important;
}
.mb-3x {
  margin-bottom: calc(#{$margin-1x} * 3) !important;
}
.mb-10x {
  margin-bottom: calc(#{$margin-1x} * 10) !important;
}
.me-3x {
  margin-right: calc(#{$margin-1x} * 3) !important;
}
.me-5x {
  margin-right: calc(#{$margin-1x} * 5) !important;
}
.me-8x {
  margin-right: calc(#{$margin-1x} * 8) !important;
}
.no-padding {
  padding: 0 !important;
}
.mb-0x {
  margin-bottom: 0 !important;
}
.pb-10x {
  padding-bottom: calc(#{$padding-1x} * 10) !important;
}
.pt-0x {
  padding-top: 0 !important;
}
.ps-5x {
  padding-left: calc(#{$padding-1x} * 5) !important;
}
.no-border {
  border: 0 !important;
}

/**
* END LIX General margin classes
*/

/*
  Equivalent classes to replace flex layout
*/

/* Horizontal end alignment and vertical center alignment */
.flex-layout-align-end-center {
  display: flex !important;
  place-content: center flex-end; /* Horizontal end alignment and vertical center alignment */
  align-items: center; /* Vertical center alignment added for retro compatibility */
}

/* Horizontal center alignment and vertical end alignment */
.flex-layout-align-center-end {
  display: flex !important;
  place-content: flex-end center; /* Horizontal center alignment and vertical end alignment */
  align-items: flex-end; /* Vertical end alignment added for retro compatibility */
}

/* Horizontal start alignment and vertical end alignment */
.flex-layout-align-start-end {
  display: flex !important;
  place-content: flex-end start; /* Horizontal start alignment and vertical end alignment */
  align-items: flex-end; /* Vertical end alignment added for retro compatibility */
}

/* Horizontal start alignment and vertical end alignment */
.flex-layout-align-start-center {
  display: flex !important;
  place-content: center start; /* Horizontal start alignment and vertical center alignment */
  align-items: center; /* Vertical center alignment added for retro compatibility */
}

/* Horizontal start alignment and vertical start alignment */
.flex-layout-align-start-start {
  display: flex !important;
  place-content: start start; /* Horizontal start alignment and vertical start alignment */
  align-items: start; /* Vertical center alignment added for retro compatibility */
}

.flex-layout-align-center-start {
  display: flex !important;
  place-content: start center; /* Horizontal center alignment and vertical start alignment */
  align-items: start; /* Vertical start alignment added for retro compatibility */
}

.flex-layout-align-end-start {
  display: flex !important;
  place-content: start end; /* Horizontal end alignment and vertical start alignment */
  align-items: start; /* Vertical start alignment added for retro compatibility */
}

.flex-layout-align-center-center {
  display: flex !important;
  place-content: center center; /* Horizontal center alignment and vertical center alignment */
  align-items: center; /* Vertical center alignment added for retro compatibility */
}

.flex-layout-align-space-between-center {
  display: flex !important;
  place-content: center space-between; /* Horizontal space between alignment and vertical center alignment */
  align-items: center; /* Vertical center alignment added for retro compatibility */
}

.flex-layout-align-space-around-none {
  display: flex !important;
  place-content: stretch space-around; /* Horizontal space around alignment and vertical stretch alignment */
  align-items: stretch; /* Vertical stretch alignment added for retro compatibility */
}

.flex-layout-align-start-stretch {
  height: 100% !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
  display: flex !important;
  place-content: stretch flex-start !important;
  align-items: stretch !important;
  max-width: 100% !important;
}

.flex {
  display: flex !important;
}

.flex-auto {
  flex: 1 1 auto !important;
  max-width: unset !important;
}

.flex-18 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 18% !important;
  max-height: 18% !important;
}

.flex-20 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 20% !important;
  max-height: 20% !important;
}

.flex-25 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 25% !important;
  max-height: 25% !important;
}

.flex-33 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 33% !important;
  max-height: 33% !important;
}

.flex-35 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 35% !important;
  max-height: 35% !important;
}

.flex-40 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 40% !important;
  max-height: 40% !important;
}

.flex-50 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 50% !important;
  max-height: 50% !important;
}

.flex-60 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 60% !important;
  max-height: 60% !important;
}

.flex-75 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 75% !important;
  max-height: 75% !important;
}

.flex-90 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 90% !important;
  max-height: 90% !important;
}

.flex-100 {
  display: flex !important;
  flex: 1 1 100% !important;
  box-sizing: border-box !important;
  max-width: 100% !important;
}

.flex-1400px {
  flex: 1 1 1400px !important;
  box-sizing: border-box !important;
  max-width: 1400px !important;
}

.flex-1600px {
  flex: 1 1 1600px !important;
  box-sizing: border-box !important;
  max-width: 1600px !important;
}

.flex-layout-column {
  display: flex !important;
  box-sizing: border-box;
  flex-direction: column !important;
}

.flex-layout-row {
  display: flex !important;
  box-sizing: border-box;
  flex-direction: row !important;
}

.flex-layout-wrap {
  display: flex !important;
  box-sizing: border-box;
  flex-wrap: wrap !important;
}

/* Extra small devices (xs) (<= 599px) */
@media screen and (max-width: 599px) {
  /* Your CSS rules for extra small devices here */
}

/* Small devices (sm) (>= 600px and <= 959px) */
@media screen and (min-width: 600px) and (max-width: 959px) {
  /* Your CSS rules for small devices here */
}

/* Medium devices (md) (>= 960px and <= 1279px) */
@media screen and (min-width: 960px) and (max-width: 1279px) {
  /* Your CSS rules for medium devices here */
}

/* Large devices (lg) (>= 1280px and <= 1919px) */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  /* Your CSS rules for large devices here */
}

/* Extra large devices (xl) (>= 1920px and <= 5000px) */
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  /* Your CSS rules for extra large devices here */
}




/* Less than extra large devices (lt-xl) (< 1920px) */
@media screen and (max-width: 1919px) {
  /* Your CSS rules for less than extra large devices here */
}

/* Less than large devices (lt-lg) (< 1280px) */
@media screen and (max-width: 1279px) {
  /* Your CSS rules for less than large devices here */
  .flex-33-lt-lg {
    display: flex !important;
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-height: 33% !important;
    max-width: 33% !important;
  }
  .flex-100-lt-lg {
    display: flex !important;
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
  }
  .hide-lt-lg {
    display: none !important;
  }
  .flex-layout-column-lt-lg {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-layout-align-center-center-lt-lg {
    place-content: center center; /* Horizontal center alignment and vertical center alignment */
    align-items: center; /* Vertical center alignment added for retro compatibility */
  }
  .flex-auto-lt-lg {
    flex: 1 1 auto !important;
  }
}

/* Less than medium devices (lt-md) (< 960px) */
@media screen and (max-width: 959px) {
  /* Your CSS rules for less than medium devices here */
  .mt-3x-lt-md {
    margin-top: calc(#{$margin-1x} * 3) !important;
  }
  .me-0x-lt-md {
    margin-right: 0 !important;
  }
  .mt-0x-lt-md {
    margin-top: 0 !important;
  }
  .flex-layout-column-lt-md {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-layout-row-lt-md {
    display: flex !important;
    flex-direction: row !important;
  }
  .hide-lt-md {
    display: none !important;
  }
  .flex-100-lt-md {
    display: flex !important;
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
  }
  .flex-layout-align-center-center-lt-md {
    place-content: center center; /* Horizontal center alignment and vertical center alignment */
    align-items: center; /* Vertical center alignment added for retro compatibility */
  }
  .flex-layout-align-start-stretch-lt-md {
    height: 100% !important;
    flex-direction: column !important;
    box-sizing: border-box !important;
    display: flex !important;
    place-content: stretch flex-start !important;
    align-items: stretch !important;
    max-width: 100% !important;
  }
  .display-none-lt-md {
    display: none !important;
  }
  .flex-50-lt-md {
    display: flex !important;
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-width: 50% !important;
    max-height: 50% !important;
  }
}

/* Less than small devices (lt-sm) (< 600px) */
@media screen and (max-width: 599px) {
  /* Your CSS rules for less than small devices here */
  .flex-layout-column-lt-sm {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-layout-row-lt-sm {
    display: flex !important;
    flex-direction: row !important;
  }
  .flex-layout-align-center-start-lt-sm {
    place-content: start center; /* Horizontal center alignment and vertical start alignment */
    align-items: start; /* Vertical start alignment added for retro compatibility */
  }
  .flex-100-lt-sm {
    display: flex !important;
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
  }
  .flex-layout-wrap-lt-sm {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .me-0x-lt-sm {
    margin-right: 0 !important;
  }
  .mt-2x-lt-sm {
    margin-top: calc(#{$margin-1x} * 2) !important;
  }
  .mt-5x-lt-sm {
    margin-top: calc(#{$margin-1x} * 5) !important;
  }
  .mt-8x-lt-sm {
    margin-top: calc(#{$margin-1x} * 8) !important;
  }
  .mb-3x-lt-sm {
    margin-bottom: calc(#{$margin-1x} * 3) !important;
  }
  .flex-layout-align-start-stretch-lt-sm {
    height: 100% !important;
    flex-direction: column !important;
    box-sizing: border-box !important;
    display: flex !important;
    place-content: stretch flex-start !important;
    align-items: stretch !important;
    max-width: 100% !important;
  }
  .display-none-lt-sm {
    display: none !important;
  }
  .flex-layout-align-start-center-lt-sm {
    display: flex !important;
    place-content: center start; /* Horizontal start alignment and vertical center alignment */
    align-items: center; /* Vertical center alignment added for retro compatibility */
  }
  .ps-0x-lt-sm {
    padding-left: 0 !important;
  }
  .flex-50-lt-sm {
    display: flex !important;
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-width: 50% !important;
    max-height: 50% !important;
  }
}



/* Greater than extra small devices (gt-xs) (> 599px) */
@media screen and (min-width: 600px) {
  /* Your CSS rules for greater than extra small devices here */
}

/* Greater than small devices (gt-sm) (> 959px) */
@media screen and (min-width: 960px) {
  /* Your CSS rules for greater than small devices here */
}

/* Greater than medium devices (gt-md) (> 1279px) */
@media screen and (min-width: 1280px) {
  /* Your CSS rules for greater than medium devices here */
}

/* Greater than large devices (gt-lg) (> 1919px) */
@media screen and (min-width: 1920px) {
  /* Your CSS rules for greater than large devices here */
}

/*
  END Equivalent classes to replace flex layout
*/

hr {
  width: 100%;
}

.block {
  h1,
  h2,
  h3 {
    color: $primary;
  }
  padding: 25px 30px 25px 30px;
  h1 {
    font-size: 70px;
    font-weight: 600;
  }
  h2 {
    font-size: 50px;
    font-weight: 600;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 14px;
  }
  p {
    font-size: 17px;
  }
}

.block li {
  padding-left: 5px;
  padding-bottom: 15px;
}
