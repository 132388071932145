// COLOR VARIABLES

// General
$accent: #0ded91;
$primary: #083ea7;
$warn: #ed3800;
$white: #ffffff;
$black: #000000;
$transparent: transparent;

// Borders
$border-gray: #d5e3f1;
$border-blue: #70acff;

// Texts
$text-gray: #54565E;
$text-light-gray: #a5aab9;
$link-text-gray: #3B3C3F;
$link-text-blue: #007bff;
$emphasis-text-gray: #39444a;
$text-black: #000D14;
$text-green: #008A00;
$text-orange: #ef532f;
$text-locale-currency: #80b3fb;

// Background
$background-white: #f8fafb;

// Theme Colors Palette
// You can get a specific color from the color palette
// with map-get(), example map-get($lix-blue, 900);
$lix-blue: (
  50: #e1e8f4,
  100: #b5c5e5,
  200: #849fd3,
  300: #5278c1,
  400: #2d5bb4,
  500: #083ea7,
  600: #07389f,
  700: #063096,
  800: #04288c,
  900: #021b7c,
  A100: #a9b5ff,
  A200: #7689ff,
  A400: #435dff,
  A700: #2a47ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$lix-green: (
  50: #e2fdf2,
  100: #b7f9de,
  200: #87f5c9,
  300: #56f1b3,
  400: #32eea2,
  500: #0eeb92,
  600: #0ce98a,
  700: #0ae57f,
  800: #08e275,
  900: #04dd63,
  A100: #ffffff,
  A200: #d1ffe3,
  A400: #9effc3,
  A700: #85ffb4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Margin & paddings

$margin-1x: 5px; // It defines a margin unit, can be used to generate general margin classes
$padding-1x: 5px; // It defines a padding unit, can be used to generate general padding classes
